import React from "react"
import { Helmet } from "react-helmet"

import "../styles/App.scss"

import logo from "../images/idleonLogo.png"
import mid from "../images/ForestNicer.png"
import bg from "../images/Bg12.png"
import bg2 from "../images/Bg9.png"
import bgcover from "../images/BgDrop.png"
import cloud1 from "../images/cloud1.png"
import cloud2 from "../images/cloud2.png"
import rightBg from "../images/Icons_Abilities3c.png"

export default function Home() {
  const seo = {
    title: "Legends of Idleon | The Free Idle MMO",
    description:
      "Form your own guild of unique characters in Legends of Idleon! With dozens of classes, monsters, and skills, there’s no end to the fun you’ll have!",
    image: "https://www.LegendsOfIdleon.com/share.png",
    url: "https://www.LegendsOfIdleon.com",
  }

  return (
    <div
      className="AppContainer"
      style={{ backgroundImage: `url(${rightBg})` }}
    >
      <Helmet>
        <title>{seo.title}</title>
        <meta name="robots" content="noindex"></meta>

        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:alt" content="Play Legends of Idleon" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />

        <link rel="icon" type="image/png" href="./favicon64.png" />
        <link rel="apple-touch-icon" href="./logo192_a.png" />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Oxygen&display=swap"
          rel="stylesheet"
        ></link>

        {/*TODO <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://www.LegendsOfIdleon.com",
                "logo": "https://www.LegendsOfIdleon.com/logo192_a.png",
                "name": "Legends of Idleon",
              }
            `}
        </script> */}
      </Helmet>
      <div className="LeftGradient" />
      <div className="RightGradient" />
      <div className="App" style={{ overflow: "hidden" }}>
        <div className="Content">
          <div className="Header" style={{ height: "calc(100% - 200px)" }}>
            <div className="bg-container">
              <img src={bg} className="Pixel App-bg" alt="logo" />
              <img src={bg2} className="Pixel App-bg2" alt="logo" />
              <img
                src={cloud1}
                className="Cloud"
                style={{ left: "50px", top: 5 + Math.random() * 60 + "%" }}
                alt="logo"
              />
              <img
                src={cloud2}
                className="Cloud"
                style={{
                  animationDelay: Math.random() * 20 - 10 + "s",
                  top: 5 + Math.random() * 60 + "%",
                }}
                alt="logo"
              />
              <img
                src={cloud1}
                className="Cloud"
                style={{
                  animationDelay: Math.random() * 20 - 10 + "s",
                  top: 5 + Math.random() * 60 + "%",
                }}
                alt="logo"
              />
              <img
                src={cloud2}
                className="Cloud"
                style={{
                  animationDelay: Math.random() * 20 - 10 + "s",
                  top: 5 + Math.random() * 60 + "%",
                }}
                alt="logo"
              />
              <img
                src={cloud1}
                className="Cloud"
                style={{
                  animationDelay: Math.random() * 20 - 10 + "s",
                  top: 5 + Math.random() * 60 + "%",
                }}
                alt="logo"
              />
              <img
                src={cloud2}
                className="Cloud"
                style={{
                  animationDelay: Math.random() * 20 - 10 + "s",
                  top: 5 + Math.random() * 60 + "%",
                }}
                alt="logo"
              />
              <img
                src={cloud1}
                className="Cloud"
                style={{
                  animationDelay: Math.random() * 20 - 10 + "s",
                  top: 5 + Math.random() * 60 + "%",
                }}
                alt="logo"
              />
              <img
                src={cloud2}
                className="Cloud"
                style={{
                  animationDelay: Math.random() * 20 - 10 + "s",
                  top: 5 + Math.random() * 60 + "%",
                }}
                alt="logo"
              />
              <img
                src={cloud1}
                className="Cloud"
                style={{
                  animationDelay: Math.random() * 20 - 10 + "s",
                  top: 5 + Math.random() * 60 + "%",
                }}
                alt="logo"
              />
              <img
                src={cloud2}
                className="Cloud"
                style={{
                  animationDelay: Math.random() * 20 - 10 + "s",
                  top: 5 + Math.random() * 60 + "%",
                }}
                alt="logo"
              />
              <img
                src={cloud1}
                className="Cloud"
                style={{
                  animationDelay: Math.random() * 20 - 10 + "s",
                  top: 5 + Math.random() * 60 + "%",
                }}
                alt="logo"
              />
              <img src={mid} className="Pixel App-mid" alt="logo" />
            </div>

            <header className="App-header">
              <img
                src={logo}
                style={{
                  animationDuration: "0s",
                }}
                className="Pixel Logo"
                alt="logo"
              />
              {typeof window !== "undefined" && (
                <a
                  className="PlayBrowserButton"
                  href={`/steamandroid/${window.location.search}`}
                  style={{
                    marginTop: "32px",
                    height: "172px",
                    display: "flex",
                    flexDirection: "column",
                    animationDelay: "0s",
                    animationDuration: "0s",
                  }}
                >
                  <div style={{ fontSize: "20px", padding: "2px" }}>
                    Press here to{" "}
                  </div>
                  <div style={{ fontSize: "24px" }} className="PlayBrowserText">
                    complete Steam sign-in
                  </div>
                  <div className="PlayBrowserText2"></div>
                </a>
              )}
            </header>
          </div>
          <div
            style={{
              background: "#241b15",
              position: "relative",
              height: "200px",
              zIndex: 3,
              overflow: "hidden",
              width: "100%",
            }}
          >
            <img
              src={bgcover}
              style={{
                position: "absolute",
                transform: "translateX(-50%)",
                width: "100%",
                minWidth: "1063px",
              }}
              className="Pixel headerDrop"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
